import appComponents from '@/router/components';

const routes = [
    {
        path: '/',
        name: 'home',
        components: {
            Content: appComponents.frontend.home,
        },
        meta: {
            title: 'Axil SERP | Local Rank Tracking SEO Tool',
        },
    },
];

export default routes;

