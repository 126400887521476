<template>
    <div class="xl:pt-12 w-full xl:w-1/2 relative pb-12 lg:pb-0">
        <div class="relative">
            <div class="absolute">
                <div class="">
                    <h1 class="my-2 text-gray-800 font-bold text-2xl mb-6">
                        404 Not Found
                    </h1>
                        <p class="my-2 text-gray-800 mb-6" v-if="message">
                            {{ message }}
                        </p>
                    <template v-else>
                    <p class="my-2 text-gray-800">We're sorry, but the page you requested could not be found.</p>
                    <p class="my-2 text-gray-800 mb-6">Please check your spelling and try again. If you believe this is an error, please contact us and let us know.</p>
                    </template>
                    <br>
                    <router-link :to="{name:'app.dashboard'}" class="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50">
                        Go To Dashboard!
                    </router-link>
                </div>
            </div>
            <div>
                <img src="https://i.ibb.co/G9DC8S0/404-2.png" />
            </div>
        </div>
    </div>
    <div>
        <img src="https://i.ibb.co/ck1SGFJ/Group.png" />
    </div>
</template>

<script setup>
import {computed} from "vue";
const props = defineProps(['message']);
const message = computed(() => props.message);
</script>

