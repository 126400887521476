import _ from 'lodash';
window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from "axios";

window.axios = axios;
window.axios.defaults.withCredentials = true;

// import {cacheAdapterEnhancer, throttleAdapterEnhancer} from 'axios-extensions';
//
// window.http = axios.create({
//     baseURL: '/',
//     headers: {'Cache-Control': 'no-cache'},
//     adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter, true))
// });
