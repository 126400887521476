<template>
    <div class="xl:pt-12 w-full xl:w-1/2 relative pb-12 lg:pb-0">
        <div class="relative">
            <div class="absolute">
                <div class="">
                    <h1 class="my-2 text-gray-800 font-bold text-2xl mb-8">
                        {{ abortCode }} Error!
                    </h1>
                    <p class="mb-12">
                    <template v-if="message">
                        {{ message }}
                    </template>
                    <template v-else>
                        Internal Server Error! If you believe this is an error, please contact us and let us know.
                    </template>
                    </p>
                    <router-link :to="{name:'app.dashboard'}" class="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50">
                        Go To Dashboard!
                    </router-link>
                </div>
            </div>
            <div>
                <img src="https://i.ibb.co/G9DC8S0/404-2.png" />
            </div>
        </div>
    </div>
    <div>
        <img src="https://i.ibb.co/ck1SGFJ/Group.png" />
    </div>
</template>

<script setup>
import {computed} from "vue";
import {useAxilwebGeoSearchAppStore} from "@accessControlSrc/stores/axilwebGeoSearchApp"
const appStore = useAxilwebGeoSearchAppStore();
const abortCode = computed(() => appStore.abort_code);

const props = defineProps(['message']);
const message = computed(() => props.message);
</script>
