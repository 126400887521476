import {createRouter, createWebHistory} from "vue-router";

// import all routes
import accessControlRoutes from "@accessControlSrc/router/routes"
import connectedAccountsRoutes from "@frontendSrc/router/routes"
import {useAxilwebGeoSearchAppStore} from "@accessControlSrc/stores/axilwebGeoSearchApp"

const AllRoutes = [
    ...accessControlRoutes,
    ...connectedAccountsRoutes
];

const router = createRouter({
    history: createWebHistory(),
    routes: AllRoutes
});


router.beforeEach(async (to, from, next) => {

    const appStore = useAxilwebGeoSearchAppStore();
    await appStore.setAbort(0);

    if (to.meta.title) {
        document.title = to.meta.title;
    }

    const authRequired = to.matched.some(route => route.meta?.required_auth);
    if (!authRequired) {
        console.log('guest access11');
        return next()
    }

    return next({
        path: '/'
    });

    if (!appStore.isUserLoggedIn) {
        return next({
            path: '/login', query: {redirect: to.fullPath}
        });
    }

    const isRequiredPermission = to.meta?.required_permission;
    const permissions = to.meta?.permissions;

    // Get permissions if not exists in the store
    if (!appStore.getPermissions) {
        await appStore.authorization();
    }

    if (to.name == 'app.notfound') {
        await appStore.setAbort(404);
        return next()
    }

    if (!isRequiredPermission) {
        return next()
    }

    const authPermissions = appStore.getPermissions;
    if (_.intersection(permissions, authPermissions).length) {
        console.log('grant access');
        return next();
    } else {
        await appStore.setAbort(403);
        console.log('unauthorized access')
        // await appStore.delay(2000);
        return next();
    }

    console.log('authorized access');
    return next();
});

router.afterEach((to, from) => {
    document.querySelector('body').classList.remove('axilweb-loading');
    document.querySelector('html').scrollTop = '0px';
})

export default router;

