/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';
import { createApp} from 'vue';
import { createPinia } from 'pinia';
import router from './router';
import { VueReCaptcha } from "vue-recaptcha-v3";


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import GeoSearchApp from '@accessControlSrc/components/layout/App';
import { vCan, vCannot, vAuthCan, vAuthCannot } from '@accessControlSrc/directive/directives';

const pinia = createPinia();
const app = createApp(GeoSearchApp);
app.directive('can', vCan)
app.directive('cannot', vCannot)
app.directive('auth-can', vAuthCan)
app.directive('auth-cannot', vAuthCannot)
app.use(VueReCaptcha, { siteKey: axilwebGeoSearchPublicConfig?.recaptcha?.key })

app.use(pinia)
    .use(router);
app.mount('#app');



