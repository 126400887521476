import appComponents from '@/router/components';

const routes = [
    // {
    //     path: '/register',
    //     name: 'register',
    //     components: {
    //         Content: appComponents.access_control.unauthenticated.register,
    //     },
    //     meta: {
    //         title: 'Register',
    //     },
    // },
    //
    // {
    //     path: '/client-account/verification',
    //     name: 'client.account.verification',
    //     components: {
    //         Content: appComponents.access_control.unauthenticated.verification,
    //     },
    //     meta: {
    //         title: 'Verification',
    //     },
    // },
    //
    // {
    //     path: '/login',
    //     name: 'login',
    //     components: {
    //         Content: appComponents.access_control.unauthenticated.login,
    //     },
    //     meta: {
    //         title: 'Login',
    //     },
    // },
    //
    // {
    //     path: '/forgot-password',
    //     name: 'forgotPassword',
    //     components: {
    //         Content: appComponents.access_control.unauthenticated.forgot,
    //     },
    //     meta: {
    //         title: 'Forget Password',
    //     },
    // },
    //
    // {
    //     path: '/reset-password/:token',
    //     name: 'resetPassword',
    //     components: {
    //         Content: appComponents.access_control.unauthenticated.reset,
    //     },
    //     meta: {
    //         title: 'Reset Password',
    //     },
    // },
    //
    // {
    //     path: '/dashboard',
    //     name: 'app.dashboard',
    //     components: {
    //         AuthHeader: appComponents.access_control.layouts.auth_header,
    //         SidebarLeft: appComponents.access_control.layouts.sidebar_left,
    //         Content: appComponents.access_control.dashboard,
    //     },
    //     meta: {
    //         title: 'Dashboard',
    //         required_auth: true,
    //         required_permission: false,
    //     },
    // },
    //
    // {
    //     path: '/admin/users',
    //     name: 'app.admin.users.list',
    //     components: {
    //         AuthHeader: appComponents.access_control.layouts.auth_header,
    //         SidebarLeft: appComponents.access_control.layouts.sidebar_left,
    //         Content: appComponents.access_control.admin.users,
    //     },
    //     meta: {
    //         title: 'Users',
    //         required_auth: true,
    //         required_permission: true,
    //         permissions: ['view_users']
    //     },
    // },
    //
    // {
    //     path: '/admin/permissions',
    //     name: 'app.admin.permissions.list',
    //     components: {
    //         AuthHeader: appComponents.access_control.layouts.auth_header,
    //         SidebarLeft: appComponents.access_control.layouts.sidebar_left,
    //         Content: appComponents.access_control.admin.permissions,
    //     },
    //     meta: {
    //         title: 'Permissions',
    //         required_auth: true,
    //         required_permission: true,
    //         permissions: ['view_permissions']
    //     },
    // },
    //
    // {
    //     path: '/admin/roles',
    //     name: 'app.admin.roles.list',
    //     components: {
    //         AuthHeader: appComponents.access_control.layouts.auth_header,
    //         SidebarLeft: appComponents.access_control.layouts.sidebar_left,
    //         Content: appComponents.access_control.admin.roles,
    //     },
    //     meta: {
    //         title: 'Roles',
    //         required_auth: true,
    //         required_permission: true,
    //         permissions: ['view_roles']
    //     },
    // },

    {
        path:'/:path(.*)',
        name: 'app.notfound',
        components: {
            Content: appComponents.access_control.layouts.index,
        },
        meta: {
            title: 'Not Found',
            required_auth: true,
        },
    },
];


export default routes;

