<template>
    <div :class="{'flex h-screen overflow-y-hidden bg-white':!!author?.id}">
<!--        <router-view name="SidebarLeft"></router-view>-->
        <div class="flex flex-col flex-1 h-full overflow-hidden">
<!--            <router-view name="AuthHeader"></router-view>-->
            <template v-if="abortCode > 300">
                <ErrorContent></ErrorContent>
            </template>
            <router-view v-else name="Content"></router-view>
<!--            <AppFooter></AppFooter>-->
        </div>
    </div>
</template>

<script setup>
import {useAxilwebGeoSearchAppStore} from "@accessControlSrc/stores/axilwebGeoSearchApp"
// import AppFooter from "./AppFooter"
import ErrorContent from "./../errors/index"
import {computed} from "vue";

const appStore = useAxilwebGeoSearchAppStore();
const abortCode = computed(() => appStore.abort_code);
const author = computed(() => appStore.getAuthor);
</script>

