import {useAxilwebGeoSearchAppStore} from "@accessControlSrc/stores/axilwebGeoSearchApp"
import _ from 'lodash';

/**
 * Usages Example
 * v-can="'permission'"
 * v-can="['permission']"
 * v-can="{permission:'permission'}"
 * v-can="{permission:'permission', any:true/false}"
 */
export const vCan = {
    async mounted(el, binding) {
        const appStore = useAxilwebGeoSearchAppStore();

        if (!appStore.getPermissions) {
            await appStore.authorization();
        }

        const authPermissions = appStore.getPermissions;

        let permissions = [];
        let isAny = true;

        const {permissions: paramsPermissions, any: paramsAny} = binding.value || {};

        if (_.isString(binding.value)) {
            permissions = [binding.value];
        } else if (_.isArray(binding.value)) {
            permissions = binding.value;
        } else if (_.isObject(binding.value) && _.isString(paramsPermissions)) {
            permissions = _.split(paramsPermissions, '|');
        }

        if (_.isBoolean(paramsAny)) {
            isAny = paramsAny;
        }

        const matchTotal = _.intersection(permissions, authPermissions).length;
        if (isAny && matchTotal === 0) {
            return el.remove();
        } else if (!isAny && matchTotal !== permissions.length) {
            return el.remove();
        }
    }
}

/**
 * Usages Example
 * v-cannot="'permission'"
 * v-cannot="['permission']"
 */
export const vCannot = {
    async mounted(el, binding) {
        const appStore = useAxilwebGeoSearchAppStore();

        if (!appStore.getPermissions) {
            await appStore.authorization();
        }
        const authPermissions = appStore.getPermissions;

        let permissions = [];
        if (_.isString(binding.value)) {
            permissions = [binding.value];
        } else if (_.isArray(binding.value)) {
            permissions = binding.value;
        }

        if (_.intersection(permissions, authPermissions).length !== 0) {
            return el.remove();
        }
    }
}

/**
 * Usages Examples:
 * v-auth-can="profile.user_id"
 * v-auth-can.username="profile.username"
 * v-auth-can="{auth:profile.user_id}"
 * v-auth-can.username="{auth:profile.username}"
 */
export const vAuthCan = {
    async mounted(el, binding) {
        const appStore = useAxilwebGeoSearchAppStore();

        if (!appStore.getAuthor) {
            await appStore.authorization();
        }
        const appAuth = appStore.getAuthor;

        const {modifiers, value} = binding;
        var modifier = Object.keys(modifiers);
        const auth = _.isString(value) ? value : value?.auth;
        var authField = !!modifier[0] ? modifier[0] : 'id';

        return appAuth[authField] !== auth ? el.remove() : undefined;
    }
}

/**
 * Usages Examples:
 * v-auth-cannot="profile.user_id"
 * v-auth-cannot.username="profile.username"
 * v-auth-cannot="{auth:profile.user_id}"
 * v-auth-cannot.username="{auth:profile.username}"
 */
export const vAuthCannot = {
    async mounted(el, binding) {
        const appStore = useAxilwebGeoSearchAppStore();

        if (!appStore.getAuthor) {
            await appStore.authorization();
        }
        const appAuth = appStore.getAuthor;

        const {modifiers, value} = binding;
        var modifier = Object.keys(modifiers);
        const auth = _.isString(value) ? value : value?.auth;
        var authField = !!modifier[0] ? modifier[0] : 'id';

        return appAuth[authField] == auth ? el.remove() : undefined;
    }
}
